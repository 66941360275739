<template>
<div class="admin-container" v-loading.fullscreen.lock="loading">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1> Templates</h1>
            <div class="flex items-center"> 
                <button class="button ml-10" @click="$router.push(`/admin/pages/${$route.params.id}/template/add`)"> Создать</button>
            </div>
        </div>
    </div>
    <div class="admin-table">
        <el-table :data="Pages.blogs" style="width: 100%;" default-expand-all>
            <el-table-column prop="title_ua" label="Title" class="table-pl-20px">
            </el-table-column> 
            <el-table-column label="Дії" width="180" class="table-pr-20px ">
                <template slot-scope="scope">
                    <div class="flex">
                        <el-button @click="$router.push(`/admin/pages/${$route.params.id}/template/edit/${scope.row.id}`)" slot="reference">Edit</el-button>
                        <el-popconfirm class="ml-10" @confirm="_DELETE(scope.row.id)" confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red" title="Are you sure to delete this?">
                            <el-button class="delete" slot="reference">Delete</el-button>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
           current_page: 1,
            page_count: 1,
            page_size: 10,
            loading: false,
            search:'',
            Pages: [],
        }

    },
    methods: {
        Get() {
            this.loading=true
            this.axios.get(`/admin/page/${this.$route.params.id}`)
                .then(res => {
                     
                    this.Pages = res.data.data
                })
                .finally(()=>{
                    this.loading=false
                })
        },
        paginateSize() {
            console.log("AAA")
        },
        _DELETE(i) {
            this.loading = true
            this.axios.delete(`/admin/blog/${i}`)
                .then(() => {
                     this.$notify({
                                title: 'Delete',
                                message: 'Ви успішно ввійшли',
                                type: 'success'
                            });
                    this.Get()
                })
                .catch(error => { 
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            this.$notify.error({
                                 title: 'Произошла ошибка',
                                message: error_msg ? error_msg : "Произошла ошибка",
                            });

                        })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        this.Get()
    }
}
</script>

<style>
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
